var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '@progress/kendo-react-common';
import util from './util';
var styles = util.styles;
/**
 * @hidden
 */

function iconElement(_a) {
  var imageUrl = _a.imageUrl,
      icon = _a.icon,
      iconClass = _a.iconClass,
      imageAlt = _a.imageAlt;

  if (imageUrl) {
    return React.createElement("img", {
      role: "presentation",
      className: 'k-image',
      alt: imageAlt,
      src: imageUrl
    });
  } else if (icon) {
    var iconClasses = classNames('k-icon', 'k-i-' + icon);
    return React.createElement("span", {
      role: "presentation",
      className: iconClasses
    });
  } else if (iconClass) {
    return React.createElement("span", {
      role: "presentation",
      className: iconClass
    });
  }

  return null;
}

var Button =
/** @class */
function (_super) {
  __extends(Button, _super);

  function Button(props) {
    var _this = _super.call(this, props) || this;

    _this._element = null;

    _this.handleClick = function (event) {
      _this.toggleIfApplicable();

      if (_this.props.onClick) {
        _this.props.onClick.call(undefined, event);
      }
    };

    _this.state = {
      active: _this.props.togglable === true && _this.props.selected === true
    };
    return _this;
  }

  Object.defineProperty(Button.prototype, "element", {
    /**
     * Gets the DOM element of the Button component.
     */
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Button.prototype, "selected", {
    /**
     * Returns `true` when the component is togglable and selected ([see example]({% slug toggleable_button %})).
     * Otherwise, returns `false`.
     */
    get: function get() {
      return this._activeTemp !== undefined ? this._activeTemp : this.state.active;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  Button.getDerivedStateFromProps = function (props, state) {
    if (props.togglable && props.selected !== undefined && props.selected !== state.active) {
      return {
        active: props.selected
      };
    }

    return null;
  };
  /**
   * @hidden
   */


  Button.prototype.render = function () {
    var _this = this;

    var _a;

    var _b = this.props,
        children = _b.children,
        look = _b.look,
        primary = _b.primary,
        togglable = _b.togglable,
        icon = _b.icon,
        iconClass = _b.iconClass,
        imageUrl = _b.imageUrl,
        imageAlt = _b.imageAlt,
        className = _b.className,
        onClick = _b.onClick,
        htmlAttributes = __rest(_b, ["children", "look", "primary", "togglable", "icon", "iconClass", "imageUrl", "imageAlt", "className", "onClick"]);

    var hasIcon = icon !== undefined || iconClass !== undefined || imageUrl !== undefined;
    var hasChildren = children !== undefined;
    var buttonClasses = classNames([styles.button], (_a = {}, _a[styles["" + look]] = look !== 'default', _a[styles.primary] = primary, _a[styles['state-disabled']] = this.props.disabled, _a[styles['state-active']] = this.state.active, _a[styles['button-icon']] = !hasChildren && hasIcon, _a[styles['button-icontext']] = hasChildren && hasIcon, _a), [styles["" + this.props.dir]], className);
    var buttonProps = {
      className: buttonClasses,
      onClick: this.handleClick,
      // Accessibility properties
      role: togglable ? 'checkbox' : undefined,
      'aria-disabled': this.props.disabled || undefined,
      'aria-checked': togglable ? this.state.active : undefined
    };
    var iconEl = iconElement({
      icon: icon,
      iconClass: iconClass,
      imageUrl: imageUrl,
      imageAlt: imageAlt
    });
    return React.createElement("button", __assign({}, buttonProps, htmlAttributes, {
      ref: function ref(button) {
        return _this._element = button;
      }
    }), iconEl, children);
  };

  Button.prototype.toggleIfApplicable = function () {
    var _this = this;

    if (this.props.togglable && this.props.selected === undefined) {
      var active = !this.state.active;
      this._activeTemp = active;
      this.setState({
        active: active
      }, function () {
        return _this._activeTemp = undefined;
      });
    }
  };
  /**
   * @hidden
   */


  Button.propTypes = {
    children: PropTypes.node,
    look: PropTypes.oneOf(['default', 'bare', 'flat', 'outline']),
    primary: PropTypes.bool,
    selected: PropTypes.bool,
    togglable: PropTypes.bool,
    icon: PropTypes.string,
    iconClass: PropTypes.string,
    imageUrl: PropTypes.string,
    imageAlt: PropTypes.string
  };
  /**
   * @hidden
   */

  Button.defaultProps = {
    look: 'default',
    primary: false,
    togglable: false
  };
  return Button;
}(React.Component);

export default Button;