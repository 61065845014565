var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { cloneDate } from '@progress/kendo-date-math';
import { classNames, Keys } from '@progress/kendo-react-common';
import { provideIntlService, registerForIntl, registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages, timePickerCancel, timePickerSet } from '../messages';
import { MIN_TIME, MAX_TIME, MIDNIGHT_DATE } from '../utils';
import { generateGetters, getNow, valueMerger } from './utils';
import { TimePart } from './TimePart';
/**
 * @hidden
 */

export var Direction;

(function (Direction) {
  Direction[Direction["Left"] = 0] = "Left";
  Direction[Direction["Right"] = 1] = "Right";
})(Direction || (Direction = {}));
/**
 * @hidden
 */


var TimeSelector =
/** @class */
function (_super) {
  __extends(TimeSelector, _super);

  function TimeSelector(props) {
    var _this = _super.call(this, props) || this;

    _this.focusActiveList = function () {
      if (!_this.timePart) {
        return;
      }

      _this.timePart.focus({
        preventScroll: true
      });
    };

    _this.handleKeyDown = function (event) {
      var keyCode = event.keyCode;

      switch (keyCode) {
        case Keys.enter:
          if (!_this.hasActiveButton()) {
            _this.handleAccept(event);
          }

          return;

        default:
          return;
      }
    };

    _this.handleAccept = function (event) {
      var value = _this.mergeValue(cloneDate(_this.value || getNow()), _this.current);

      _this.setState({
        value: value
      });

      _this.valueDuringOnChange = value;
      var onChange = _this.props.onChange;

      if (onChange) {
        onChange.call(undefined, {
          syntheticEvent: event,
          nativeEvent: event.nativeEvent,
          value: _this.value,
          target: _this
        });
      }

      _this.valueDuringOnChange = undefined;
    };

    _this.handleReject = function (event) {
      _this.setState({
        current: _this.value
      });

      var onReject = _this.props.onReject;

      if (onReject) {
        onReject.call(undefined, event);
      }
    };

    _this.handleNowClick = function (event) {
      var now = _this.mergeValue(cloneDate(_this.value || getNow()), getNow());

      _this.setState({
        current: now,
        value: now
      });

      _this.valueDuringOnChange = now;
      var onChange = _this.props.onChange;

      if (onChange) {
        onChange.call(undefined, {
          syntheticEvent: event,
          nativeEvent: event.nativeEvent,
          value: _this.value,
          target: _this
        });
      }

      _this.valueDuringOnChange = undefined;
    };

    _this.handleChange = function (candidate) {
      _this.setState({
        current: candidate
      });
    };

    _this.dateFormatParts = _this.intl.splitDateFormat(_this.props.format || TimeSelector.defaultProps.format);
    _this.mergeValue = valueMerger(generateGetters(_this.dateFormatParts));
    _this.hasActiveButton = _this.hasActiveButton.bind(_this);
    _this.state = {
      current: _this.props.value || MIDNIGHT_DATE,
      value: _this.props.value || TimeSelector.defaultProps.value
    };
    return _this;
  }

  Object.defineProperty(TimeSelector.prototype, "element", {
    /**
     * @hidden
     */
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimeSelector.prototype, "value", {
    get: function get() {
      var value = this.valueDuringOnChange !== undefined ? this.valueDuringOnChange : this.props.value !== undefined ? this.props.value : this.state.value;
      return value !== null ? cloneDate(value) : null;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimeSelector.prototype, "intl", {
    get: function get() {
      return provideIntlService(this);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimeSelector.prototype, "current", {
    get: function get() {
      return this.state.current !== null ? cloneDate(this.state.current) : null;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  TimeSelector.prototype.componentWillUnmount = function () {
    clearTimeout(this.nextTickId);
  };
  /**
   * @hidden
   */


  TimeSelector.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        format = _a.format,
        cancelButton = _a.cancelButton,
        disabled = _a.disabled,
        tabIndex = _a.tabIndex,
        className = _a.className,
        smoothScroll = _a.smoothScroll,
        min = _a.min,
        max = _a.max,
        boundRange = _a.boundRange,
        nowButton = _a.nowButton,
        steps = _a.steps;
    var localizationService = provideLocalizationService(this);
    var cancelMessage = localizationService.toLanguageString(timePickerCancel, messages[timePickerCancel]);
    var setMessage = localizationService.toLanguageString(timePickerSet, messages[timePickerSet]);
    return React.createElement("div", {
      ref: function ref(el) {
        _this._element = el;
      },
      tabIndex: !disabled ? tabIndex || 0 : undefined,
      className: classNames('k-timeselector k-reset', className, {
        'k-state-disabled': disabled
      }),
      onKeyDown: this.handleKeyDown
    }, React.createElement(TimePart, {
      ref: function ref(el) {
        _this.timePart = el;
      },
      value: this.current,
      onChange: this.handleChange,
      onNowClick: this.handleNowClick,
      format: format,
      smoothScroll: smoothScroll,
      min: min,
      max: max,
      boundRange: boundRange,
      disabled: disabled,
      nowButton: nowButton,
      steps: steps
    }), React.createElement("div", {
      className: "k-time-footer k-action-buttons"
    }, cancelButton && React.createElement("button", __assign({
      ref: function ref(btn) {
        _this._cancelButton = btn;
      },
      className: "k-button k-time-cancel",
      onClick: this.handleReject,
      title: cancelMessage
    }, {
      'aria-label': cancelMessage
    }), cancelMessage), React.createElement("button", __assign({
      ref: function ref(btn) {
        _this._acceptButton = btn;
      },
      className: "k-time-accept k-button k-primary",
      onClick: this.handleAccept,
      title: setMessage
    }, {
      'aria-label': setMessage
    }), setMessage)));
  };

  TimeSelector.prototype.nextTick = function (f) {
    // XXX: use setTimeout due to async focus/blur events in IE, and missing relatedTarget prop.
    // XXX: https://github.com/facebook/react/issues/3751
    clearTimeout(this.nextTickId);
    this.nextTickId = setTimeout(function () {
      return f();
    });
  };

  TimeSelector.prototype.hasActiveButton = function () {
    if (!this._acceptButton) {
      return false;
    }

    return document.activeElement === this._acceptButton || document.activeElement === this._cancelButton;
  };

  TimeSelector.propTypes = {
    cancelButton: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    format: PropTypes.string,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    nowButton: PropTypes.bool,
    steps: PropTypes.shape({
      hour: PropTypes.number,
      minute: PropTypes.number,
      second: PropTypes.number
    }),
    smoothScroll: PropTypes.bool,
    tabIndex: PropTypes.number,
    value: PropTypes.instanceOf(Date)
  };
  TimeSelector.defaultProps = {
    value: null,
    disabled: false,
    cancelButton: true,
    format: 't',
    min: MIN_TIME,
    max: MAX_TIME,
    boundRange: false
  };
  return TimeSelector;
}(React.Component);

export { TimeSelector };
registerForIntl(TimeSelector);
registerForLocalization(TimeSelector);